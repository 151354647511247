import React from "react";
import { graphql } from "gatsby";
import Header from "Commons/Header";
import Footer from "Commons/Footer";
import InternalTrainingPage from "Components/Trainings/InternalTrainingPage";
import { Helmet } from "react-helmet";
import useLang from "Commons/Hooks/useLang";

const AppSecForDevelopersPage = ({ data, location }) => {
  const [lang] = useLang();
  const {
    appsecForDevelopersPageTitle,
    appsecForDevelopersTitle,
    appsecForDevelopersDescription,
  } = data.allContentfulTrainingTexts.nodes[lang];
  return (
    <>
      <Helmet>
        <title>{appsecForDevelopersPageTitle}</title>
      </Helmet>
      <Header noScrollEffect />
      <InternalTrainingPage
        title={appsecForDevelopersTitle}
        richText={appsecForDevelopersDescription}
        currentTraining={location.pathname}
      />
      <Footer />
    </>
  );
};

export const query = graphql`
  query getAppSecForDevelopersPageText {
    allContentfulTrainingTexts {
      nodes {
        appsecForDevelopersPageTitle
        appsecForDevelopersTitle
        appsecForDevelopersDescription {
          raw
        }
        webHackingTitle
        webHackingSynopsis {
          webHackingSynopsis
        }
        dominatingBurpSuiteTitle
        dominatingBurpSuiteSynopsis {
          dominatingBurpSuiteSynopsis
        }
      }
    }
  }
`;

export default AppSecForDevelopersPage;
